import { CdkColumnDef } from '@angular/cdk/table';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Store, select } from '@ngrx/store';
import { environment } from 'carehub-environment/environment';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import {
  SmartListCriteria,
  SmartListResult,
} from 'carehub-root/shared/smartlist';
import * as fromRoot from 'carehub-root/state/app.state';
import {
  PermissionScopes,
  checkPermission,
} from 'carehub-shared/directives/if-allowed.directive';
import { LookupPipe } from 'carehub-shared/pipes/lookup.pipe';
import * as fromShared from 'carehub-shared/state/index';
import { User } from 'carehub-shared/state/shared.reducer';
import { takeUntil } from 'rxjs/operators';
import { IconDetails } from '../icon-details';
import {
  ColumnDetails,
  ColumnTextAlign,
} from '../smartlist-grid/column-details';
import { RowDetails } from '../smartlist-grid/row-details';
import { RowToolTipDetails } from '../smartlist-grid/row-tooltip-details';

@Component({
  selector: 'ch-expandable-rows-table',
  templateUrl: './expandable-rows-table.component.html',
  styleUrls: ['./expandable-rows-table.component.scss'],
  providers: [LookupPipe, CdkColumnDef],
})
export class ExpandableRowsTableComponent<TDomainObject>
  extends BaseComponent
  implements OnInit
{
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any>;
  @ContentChild('rowTemplate') rowTemplate: TemplateRef<any>;
  @ViewChild(MatAccordion) gridAccordion: MatAccordion;
  @Input()
  columns: ColumnDetails[];
  @Input()
  rowDetails: RowDetails;
  @Input() rowToolTipDetails: RowToolTipDetails;
  @Input() gridTitle: string;
  @Input() permissionName: string;
  @Input() smartListCriteria: SmartListCriteria;
  @Input() disableAdd = true;
  @Input() allowOpenMultiple = false;
  @Input() showPaginator = true;
  @Input() hideToggle = false;
  @Input() expandFirstRow = false;
  @Output() page = new EventEmitter<{ pageIndex: number; pageSize: number }>();
  @Output() sort = new EventEmitter<{
    sortField: string;
    sortDirection: string;
  }>();
  @Output() rowClicked = new EventEmitter<TDomainObject>();
  @Output() addClick = new EventEmitter<void>();
  @Input() infiniteScroll = false;
  private _smartListResult: SmartListResult<TDomainObject>;
  @Input() set dataSource(
    value: SmartListResult<TDomainObject> | TDomainObject[]
  ) {
    if (Array.isArray(value)) {
      this._smartListResult = {
        currentPage: 1,
        pageCount: 1,
        pageSize: value.length,
        results: value,
        rowCount: value.length,
      };
      this.dataSourceObject = Object.assign(
        {},
        this.dataSourceObject
      ) as SmartListResult<TDomainObject>;
      this.dataSourceObject.results = value;
    } else {
      this._smartListResult = value;
      this.dataSourceObject = value;
    }
    this.isLoading = false;
  }

  @Input() isLoadingInput: boolean = null;
  private isLoading = false;
  currentUser: User;

  expandedElement: TDomainObject;
  dataSourceObject: SmartListResult<TDomainObject>; // Data used to render the rows
  selectedItem: TDomainObject;

  /** flag to suppress the template help message, based on {@link environment.production}. In production like builds (ie all non-local hosted instances), hides the message */
  get showTemplateHelp(): boolean {
    return !environment.production;
  }

  get progressMode() {
    return this.isLoadingInput ||
      this.isLoading ||
      !this.dataSourceObject ||
      this.dataSourceObject.isLoading
      ? 'indeterminate'
      : 'determinate';
  }

  get isReadingAllowed(): boolean {
    const result = checkPermission(
      this.permissionName,
      PermissionScopes.READ,
      this.currentUser
    );
    return result;
  }

  get rowCount() {
    if (this.infiniteScroll) {
      return Number.POSITIVE_INFINITY;
    }

    return this._smartListResult && this._smartListResult.rowCount;
  }

  ColumnTextAlign = ColumnTextAlign; // make enum accessible in view

  constructor(private store: Store<fromRoot.State>) {
    super();
    this.store
      .pipe(takeUntil(this.unsubscribe$), select(fromShared.getCurrentUser))
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  ngOnInit() {}

  protected onDestroy(): void {
    this.addClick?.complete();
    this.page?.complete();
    this.sort?.complete();
    this.rowClicked?.complete();
  }
  getCellValueIcons(cellValue: any) {
    return cellValue && cellValue.icons;
  }
  typeOf(column: ColumnDetails, val: any): string {
    // !important: the control type should short-circuit
    // value type checking.

    if (column.type == 'control') {
      throw new Error('The expandable grid does not support controls');
    }

    if (val instanceof String || typeof val === 'string') {
      return 'string';
    }

    if (column && column.iconSets) {
      return 'icons';
    }

    if (val instanceof Number) {
      return 'number';
    }

    if (val instanceof Array) {
      return 'array';
    }

    if (val && val.pipe) {
      return 'observable';
    }

    return 'string';
  }
  getIcon(index: number, column: any, iconEntry: any): IconDetails {
    let iconDetails = column.iconSets[index][iconEntry];
    if (!iconDetails) {
      iconDetails = column.iconSets[index]['default'];
    }
    return iconDetails;
  }
  onIconClick(event: any, rowValue: any, iconDetails: IconDetails) {
    if (iconDetails) {
      event.preventDefault();
      event.stopPropagation();

      if (iconDetails.urlFieldName && rowValue[iconDetails.urlFieldName]) {
        throw new Error(
          'Support for url icons has been removed in the expanding grid component'
        );
      }

      if (iconDetails.click) {
        iconDetails.click(rowValue);
      }
    }
  }
  itemRowClicked(item: TDomainObject) {
    this.expandedElement = item;
    this.rowClicked.emit(item);
  }

  onPage(payload: { pageIndex: number; pageSize: number }) {
    this.page.emit(payload);
  }

  onSort(payload: { active: string; direction: string }) {
    let toEmit = {
      sortField: payload.active,
      sortDirection: payload.direction,
    };
    this.sort.emit(toEmit);
  }

  onAdd(event: any): void {
    this.addClick.emit();
  }
  determineLink() {
    let result = null;

    if (this.addClick.observers.length === 0) {
      result = './00000000-0000-0000-0000-000000000000';
    }

    return result;
  }

  // #region collapse/expand apis
  /** collapses all open rows. Required {@link allowOpenMultiple} to be enabled */
  collapseAll(): void {
    if (this.allowOpenMultiple) {
      this.gridAccordion.closeAll();
    } else {
      throw Error(
        "can not use the close all apis when 'allowOpenMultiple' is false"
      );
    }
  }
  // #endregion collapse expand apis
}
