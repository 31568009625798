<ch-auto-multi-select
  [placeholder]="placeholder"
  [allOptions]="allItems"
  [(selectedIds)]="selectedIds"
  [displayValue]="displayItem"
  [sortBy]="sortBy"
  [useFormStyles]="useFormStyles"
  [customDisplayFunction]="customDisplayFunction"
  [compact]="compact"
>
</ch-auto-multi-select>
