import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from 'carehub-shared/components/base-component';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { map, takeUntil, throttleTime } from 'rxjs/operators';

/** A multi-select option */
export interface Selectable {
  /** unique identifier */
  id: number | string;
  name: string;
}

@Component({
  selector: 'ch-auto-multi-select',
  templateUrl: './auto-multi-select.component.html',
  styleUrls: ['./auto-multi-select.component.scss'],
})
export class AutoMultiSelectComponent extends BaseComponent implements OnInit {
  /** control for the selected bank for multi-selection */
  public multiSelectCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public multiSelectFilterCtrl: FormControl = new FormControl();

  private _allOptions: Selectable[] = [];
  /** list of options */
  @Input() public set allOptions(value: Selectable[]) {
    if (this.sortBy) {
      value = value.sort(this.sortBy);
    }
    this._allOptions = value || [];
    let filter = this.multiSelectFilterCtrl.value as string;
    this.filterOptions(filter ? filter : '');
    this.selectedChange.emit(this.selected);
    this.multiSelectCtrl.setValue(this.selected);
  }
  public get allOptions(): Selectable[] {
    return this.sortBy
      ? this._allOptions.sort(this.sortBy)
      : this._allOptions.slice();
  }

  // storing the selected by id allows for selected and all options to be set
  //  in any order, esp if the options are loading for a default value
  /** the selected values */
  public get selected(): Selectable[] {
    return this._selectedIds
      .map((id) => this.allOptions.find((option) => option.id === id))
      .filter((x) => !!x);
  }
  @Input() public set selected(value: Selectable[]) {
    this.selectedIds = value ? value.map((x) => x.id) : [];
  }
  @Output() public selectedChange: EventEmitter<Selectable[]> =
    new EventEmitter<Selectable[]>();
  /** the selected value ids */
  private _selectedIds: (number | string)[] = [];
  public get selectedIds(): (number | string)[] {
    return this._selectedIds.slice();
  }
  @Input() public set selectedIds(value: (number | string)[]) {
    // since the value is either a number of a string, use the default sort
    let ordered = value ? this.sortSelected(value) : [];
    if (!_.isEqual(this._selectedIds, ordered)) {
      this._selectedIds = ordered;
      this.selectedIdsChange.emit(this.selectedIds);
      this.selectedChange.emit(this.selected);
      this.multiSelectCtrl.setValue(this.selected);
    }
  }
  @Output() public selectedIdsChange: EventEmitter<(number | string)[]> =
    new EventEmitter<(number | string)[]>();

  /** input placeholder text */
  @Input() placeholder: string;

  /** Custom display function passed from parent component
   * So that this function is known to this component and will be used from here
   */
  @Input() customDisplayFunction: (itemId: number) => string;

  /** selects the display value. defaults to a property called name. */
  @Input() displayValue: (item: Selectable) => string = (item: Selectable) =>
    item.name;

  /** use the form field styling, defaults to false */
  @Input() useFormStyles: false;
  @Input() compact: false;

  @Input() sortBy: (item1: Selectable, item2: Selectable) => number = null;

  /** list of banks filtered by search keyword for multi-selection */
  public filteredOptions: ReplaySubject<Selectable[]> = new ReplaySubject<
    Selectable[]
  >(1);

  constructor() {
    super();
  }

  /** lifecycle hook for startup */
  ngOnInit() {
    // load the initial bank list
    this.filteredOptions.next(this.allOptions.slice());

    // listen for search field value changes
    this.multiSelectFilterCtrl.valueChanges
      .pipe(takeUntil(this.unsubscribe$), throttleTime(300))
      .subscribe((filter: string) => {
        this.filterOptions(filter);
      });

    // inform on selected change
    this.multiSelectCtrl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        map((selected) => selected.filter((item: Selectable) => !!item))
      )
      .subscribe((selected: Selectable[]) => {
        this.selectedChange.emit(selected);
        this.selectedIdsChange.emit(selected.map((x) => x.id));
      });
  }
  /** lifecycle hook to clean up resources */
  protected onDestroy(): void {
    this.selectedChange.complete();
    this.selectedIdsChange.complete();
  }
  /** changes the select-all state */
  public toggleSelectAll(selectAllValue: boolean): void {
    this.multiSelectCtrl.setValue(
      selectAllValue ? this.allOptions.slice() : []
    );
  }
  /** Returns a true if all items are selected. Else returns false. */
  public selectAllChecked(): boolean {
    return this.countSelected() === this.allOptions.length;
  }
  /** Returns true if some, but not all, items are selected. Else returns false. */
  public selectAllShowIntermediate(): boolean {
    return (
      this.countSelected() > 0 && this.countSelected() < this.allOptions.length
    );
  }
  /** gets the count of items selected */
  private countSelected(): number {
    return this.multiSelectCtrl.value.length;
  }
  /** handles array sorting for arrays of union type number|string  */
  private sortSelected(value: (number | string)[]): (number | string)[] {
    if (value.some((e) => Number.isNaN(parseInt(e.toString())))) {
      return value.sort();
    } else {
      return value.map((x) => +x).sort((a, b) => a - b);
    }
  }
  /**
   * for a given item, renders the user friendly representation. also used to filter.
   * @param item the item to render
   * @param nullValue the value to use if the item is null
   */
  public renderDisplayValue(item: Selectable, nullValue: string = '') {
    const display = this.displayValue(item);
    return display ? display : nullValue;
  }
  /** filters options based on the filter value and the options display value */
  private filterOptions(filter: string) {
    if (!this.allOptions || this.allOptions.length === 0 || filter == null) {
      return;
    }
    // get the search keyword
    if (!filter) {
      this.filteredOptions.next(this.allOptions.slice());
      return;
    } else {
      filter = filter.trim().toLowerCase();
    }
    // filter the options
    let filtered = this.allOptions.filter(
      (option) =>
        this.renderDisplayValue(option).toLowerCase().indexOf(filter) > -1
    );
    this.filteredOptions.next(filtered);
  }
}
