import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AuditApiService,
  getAudits_Filter,
} from 'carehub-api/audit-api.service';
import { EntityAudit } from 'carehub-api/models/common/entityaudit';
import { ColumnDetails } from 'carehub-root/shared/components/controls/smartlist-grid/column-details';
import { LookupPipe } from 'carehub-root/shared/pipes/lookup.pipe';
import {
  SmartListCriteria,
  SmartListResult,
} from 'carehub-root/shared/smartlist';
import { FilterableComponent } from 'carehub-shared/components/filterable-component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnesthesiologistComponent } from '../../anesthesiologists/components/anesthesiologist/anesthesiologist.component';
import { ContractComponent } from '../../contracts/components/contract/contract.component';
import { FacilityComponent } from '../../facilities/components/facility/facility.component';
import { ProviderGroupComponent } from '../../providergroups/components/providergroup/providergroup.component';
import { ProviderComponent } from '../../providers/components/provider/provider.component';

@Component({
  templateUrl: './entity-history.component.html',
  styleUrls: ['./entity-history.component.scss'],
  providers: [LookupPipe],
})
export class EntityHistoryComponent extends FilterableComponent<
  getAudits_Filter,
  EntityAudit
> {
  includedColumns: ColumnDetails[] = [
    {
      columnDef: 'createdDate',
      sortable: true,
      width: '100px',
      header: 'Date',
      cell: (row: EntityAudit) =>
        row.createdDate
          ? formatDate(`${row.createdDate}`, 'medium', 'en-US')
          : '',
    },
    {
      columnDef: 'createdUserName',
      sortable: true,
      width: '100px',
      header: 'User',
      cell: (row: EntityAudit) => row.createdUserFullName,
    },
    {
      columnDef: 'description',
      width: '250px',
      header: 'Action',
      cell: (row: EntityAudit) => row.description,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private providersApiService: AuditApiService
  ) {
    super({
      sortField: 'createdDate',
      sortDirection: 'desc',
      pageSize: 10,
    });

    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      let id = this.route.parent.snapshot.params['id'];
      let parentComponent = this.route.parent.component;
      this.applyFilter(parentComponent, id);
    });
  }

  applyFilter(component: any, id: string) {
    switch (component) {
      case ProviderComponent:
        this.filter = {
          ...this.filter,
          providerId: id,
        };
        break;
      case AnesthesiologistComponent:
        this.filter = {
          ...this.filter,
          anesthesiologistId: id,
        };
        break;
      case FacilityComponent:
        this.filter = {
          ...this.filter,
          facilityId: id,
        };
        break;
      case ContractComponent:
        this.filter = {
          ...this.filter,
          contractId: id,
        };
        break;
      case ProviderGroupComponent:
        this.filter = {
          ...this.filter,
          providerGroupId: id,
        };
        break;
      default:
    }
  }

  filterMethod(
    criteria: SmartListCriteria,
    filter: Partial<getAudits_Filter>
  ): Observable<SmartListResult<EntityAudit>> {
    return this.providersApiService.getAudits(criteria, filter);
  }
}
