import { Directive } from '@angular/core';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import { SmartListCriteria, SmartListResult } from 'carehub-shared/smartlist';
import { Utils } from 'carehub-shared/utils';
import { Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  delay,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

// todo: this needs another generic type parameter representing the type of the api-service being
// injected so that we can use keyof TService to clamp the allowed method names to only those
// that are legal.
@Directive()
export abstract class FilterableComponent<
  TFilterObjectType,
  TResultObjectType
> extends BaseComponent {
  isLoading: boolean = true;

  dataSource$: Observable<SmartListResult<TResultObjectType>>;
  dataSource: SmartListResult<TResultObjectType>;
  smartListCriteria: SmartListCriteria = SmartListCriteria.default();

  private _filter: Partial<TFilterObjectType> = {};

  public get filter(): Partial<TFilterObjectType> {
    return this._filter;
  }

  public set filter(value: Partial<TFilterObjectType>) {
    this._filter = { ...this._filter, ...value };

    this.filter$.next(this._filter);
  }

  public filter$: Subject<Partial<TFilterObjectType>> = new Subject<
    Partial<TFilterObjectType>
  >();

  public onFilter(property: keyof TFilterObjectType, value: any) {
    this.filter[property] = value;
    this.loadData();
  }

  public onSmartListFilter(value: any) {
    this.smartListCriteria.filter = value;
    this.loadData();
  }

  public onDateFilter(property: keyof TFilterObjectType, date: string) {
    const tempDate: Date = new Date(date);
    this.onFilter(property, Utils.formatLocalOffset(tempDate));
  }

  public onPage(payload: { pageIndex: number; pageSize: number }) {
    Object.assign(this.smartListCriteria, payload);
    this.loadData(false);
  }

  public onSort(payload: { sortField: string; sortDirection: string }) {
    Object.assign(this.smartListCriteria, payload);
    this.loadData();
  }

  /**
   * requests data based on the current filter values
   * @param resetPage a flag to reset the page count, if the filtering criteria has changed
   */
  loadData(resetPage: boolean = true) {
    if (resetPage) {
      this.smartListCriteria.pageIndex = 0;
    }
    this.isLoading = true;
    this.filter$.next(this.filter);
  }

  constructor(
    public defaultCriteriaOverrides: Partial<SmartListCriteria>,
    filterOverride?: Partial<TFilterObjectType>,
    private lazyInit: boolean = false
  ) {
    super();

    this.smartListCriteria = {
      ...this.smartListCriteria,
      ...defaultCriteriaOverrides,
    };

    this._filter = {
      ...this._filter,
      ...filterOverride,
    };
  }

  abstract filterMethod(
    criteria: SmartListCriteria,
    filter: Partial<TFilterObjectType>
  ): Observable<SmartListResult<TResultObjectType>>;

  ngOnInit() {
    // create the pipe between the filter subject and the data stream
    this.dataSource$ = this.filter$.pipe(
      debounceTime(100),
      delay(0),
      takeUntil(this.unsubscribe$),
      switchMap((filter) => {
        if (this.filterMethod) {
          return this.filterMethod(this.smartListCriteria, filter).pipe(
            take(1)
          );
        }
        console.warn(
          'Please set apiService or filterEndpointName on filterable components.'
        );
        return of(null as SmartListResult<TResultObjectType>);
      })
    );

    this.dataSource$.subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.dataSource = res;
      }
    });
  }

  ngAfterViewInit() {
    if (!this.lazyInit) {
      this.loadData();
    }
  }

  onDestroy() {
    this.filter$.complete();
  }
}
