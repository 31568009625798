import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'carehub-shared/components/base-component';
import {
  ColumnDetails,
  ColumnTextAlign,
} from 'carehub-shared/components/controls/smartlist-grid/column-details';
import { SmartListResult } from 'carehub-shared/smartlist';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { HealthApiService } from '../../../rest-services/chiliSauceApi/health-api.service';

import { formatDate } from '@angular/common';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss'],
})
export class HealthComponent extends BaseComponent implements OnInit {
  health: any;
  health$: Observable<any>;
  healthDetails: any;

  expandedService: any;
  expandedServiceDetails: any;

  smartListResult: SmartListResult<any> = {
    results: null,
    currentPage: 0,
    pageCount: 1,
    pageSize: 50,
    rowCount: 0,
    isLoading: false,
  };

  smartListResultService: SmartListResult<any> = {
    results: null,
    currentPage: 0,
    pageCount: 1,
    pageSize: 50,
    rowCount: 0,
    isLoading: false,
  };

  smartListResultDetails: SmartListResult<any> = {
    results: null,
    currentPage: 0,
    pageCount: 1,
    pageSize: 50,
    rowCount: 0,
    isLoading: false,
  };

  itemRowClicked(item: { name: string; status: string }) {
    this.expandedService = this.healthDetails[item.name].results;
    this.healthRowsService();
  }

  itemRowClickedService(item: any) {
    this.expandedServiceDetails = this.expandedService[item.name];
    this.healthRowsServiceDetails();
  }

  setSmartList(results: any[]) {
    this.smartListResult.rowCount = results.length;
    this.smartListResult.results = results;
  }

  setSmartListService(results: any[]) {
    this.smartListResultService.rowCount = results.length;
    this.smartListResultService.results = results;
  }

  setSmartListServiceDetails(results: any[]) {
    this.smartListResultDetails.rowCount = results.length;
    this.smartListResultDetails.results = results;
  }

  healthRows() {
    let list: any[] = [];
    for (let i in this.healthKeys) {
      list.push({
        name: this.healthKeys[i],
        status: this.health[this.healthKeys[i]],
      });
    }
    this.setSmartList(list);
  }

  healthRowsService() {
    let list: any[] = [];
    for (let i in this.healthKeysService) {
      list.push({ name: this.healthKeysService[i] });
    }
    this.setSmartListService(list);
  }

  healthRowsServiceDetails() {
    let list: any[] = [];
    for (let i in this.expandedServiceDetails) {
      list.push({ description: this.expandedServiceDetails[i] });
    }
    this.setSmartListServiceDetails(list);
  }

  get healthKeys() {
    if (this.health) {
      return Object.keys(this.health);
    }
    return null;
  }

  get healthKeysService() {
    if (this.expandedService) {
      return Object.keys(this.expandedService);
    }
    return null;
  }

  getIconByStatus(item: string) {
    let status = this.health[item];
    switch (status) {
      case 'Healthy':
        return 'check_circle';
      case 'Warning':
        return 'info';
      case 'Error':
        return 'report';
    }
    return null;
  }

  getIconIndexByStatus(item: string) {
    switch (item) {
      case 'Healthy':
        return 1;
      case 'Warning':
        return 2;
      case 'Error':
        return 3;
    }
    return null;
  }

  statusIconSet = [
    {
      '1': {
        icon: 'check_circle',
        color: '#00FF00',
        toolTip: 'Healthy',
      },
      '2': {
        icon: 'info',
        color: 'yellow',
        toolTip: 'Warning',
      },
      '3': {
        icon: 'report',
        color: '#FF0000',
        toolTip: 'Error',
      },
    },
  ];

  includedColumns: ColumnDetails[] = [
    {
      columnDef: 'serviceName',
      header: 'Service Name',
      sortable: false,
      alignText: ColumnTextAlign.Left,
      cell: (service: any) => {
        return service.name;
      },
    },
    {
      columnDef: 'serviceStatus',
      header: 'Status',
      sortable: false,
      alignText: ColumnTextAlign.Left,
      cell: (service: any) => {
        return { icons: [this.getIconIndexByStatus(service.status)] };
      },
      iconSets: this.statusIconSet,
    },
    {
      columnDef: 'timeStamp',
      header: 'Timestamp',
      sortable: false,
      alignText: ColumnTextAlign.Left,
      cell: (service: any) => {
        const item = this.healthDetails && this.healthDetails[service.name];
        return item ? formatDate(item.timestamp, 'long', 'en-US') : '';
      },
      iconSets: this.statusIconSet,
    },
  ];

  includedColumnsService: ColumnDetails[] = [
    {
      columnDef: 'type',
      header: 'Type',
      sortable: false,
      alignText: ColumnTextAlign.Left,
      cell: (row: any) => {
        return row.name;
      },
    },
    {
      columnDef: 'count',
      header: 'Count',
      sortable: false,
      alignText: ColumnTextAlign.Left,
      cell: (row: any) => {
        return this.expandedService[row.name].length;
      },
    },
  ];

  subTableColumnsToDisplay: ColumnDetails[] = [
    {
      columnDef: 'description',
      header: 'Description',
      sortable: false,
      alignText: ColumnTextAlign.Left,
      cell: (row: any) => {
        return row.description;
      },
    },
  ];

  constructor(private healthService: HealthApiService) {
    super();
  }

  ngOnInit() {
    this.health$ = this.healthService.getHealthCheckSummary();
    this.health$.pipe(first()).subscribe((summary) => {
      this.health = summary;
      this.healthRows();
    });

    this.fetchDetails();
  }

  protected onDestroy(): void {}

  private fetchDetails() {
    this.healthService
      .getHealthCheckDetails()
      .pipe(first())
      .subscribe((val) => {
        this.healthDetails = val;
      });
  }

  public dumpToConsole() {
    console.log('Heath Details:');
    console.log(this.healthDetails);
  }

  public clearCache() {
    this.healthService.clearHealthCheckCache();
    window.location.reload();
  }
}
