import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Utils } from 'carehub-root/shared/utils';
import { LookupService } from 'carehub-shared/services/lookup.service';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';
import { LookupItem } from 'carehub-shared/state/shared.reducer';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export enum SmartFieldTypes {
  Text = 'text',
  Autocomplete = 'autocomplete',
  Number = 'number',
  Phone = 'phone',
  Lookup = 'lookup',
  PickList = 'picklist',
  Multi = 'multi',
  Date = 'date',
  Time = 'time',
  Ssn = 'ssn',
  Checkbox = 'checkbox',
  Currency = 'currency',
  /** same as currency, but with no decimals shown */
  Currency0 = 'currency0',
  Percentage = 'percentage',
  /** same as currency, but allows upto 3 decimal */
  Currency3 = 'currency3',
}

@Component({
  selector: 'ch-smart-field',
  templateUrl: './smart-field.component.html',
  styleUrls: ['./smart-field.component.scss'],
})
export class SmartFieldComponent implements OnInit {
  @Input() hideNotifiers = false;
  @Input() type: string;
  @Input() excludedValues: string;
  @Input() subtype: string | null;
  @Input() disabled: boolean;
  // ? If set to false, this will not run the filter logic for lookupItems.
  @Input() filterInactive = true;
  @Input() useV2Branding: boolean;

  errorLogged: boolean = false;

  private _required: boolean | null;
  @Input()
  set required(value: boolean | null) {
    this._required = value;
  }

  get required(): boolean {
    return this._required != null ? this._required : this.controlRequired;
  }

  _maxlength: number;

  @Input()
  set maxlength(value: number) {
    this._maxlength = value;
  }

  get maxlength(): number {
    if (this._maxlength) {
      return this._maxlength;
    }
  }

  get isReadonly(): boolean {
    return this.disabled || (this.formGroup && this.formGroup.disabled);
  }

  @Input() disabledItems: any[];
  @Input() propertyName: string;

  /*
   * when the value is '.' the raw entry is used,
   * otherwise the entry[optionPropertyName] is accessed
   */
  @Input() optionPropertyName: string;
  @Input() displayName: string | null;
  @Input() dropdownDisplayFn: (entity: any) => string;
  @Input() formGroup: FormGroup;
  @Input() displayMessage: { [key: string]: string };

  entries$: Observable<LookupItem[]>;

  @Input() set entries(entries: Observable<LookupItem[]> | LookupItem[]) {
    if (this.entries && (<any>this.entries).subscribe) {
      this.entries$ = entries as Observable<LookupItem[]>;
    } else {
      this.entries$ = of(entries as LookupItem[]);
    }
  }

  @Output() blur = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  controlRequired = false;

  @Input() autocompleteEntries: any[];
  @Input() autocompleteFilterTerm: string;
  filteredAutocompleteEntries: any[];

  constructor(private lookupService: LookupService) {}

  ngOnInit() {
    const control = this.formGroup.controls[this.propertyName];
    this.controlRequired = Utils.hasRequiredField(control);

    if (this.type === SmartFieldTypes.Date) {
      control.addValidators(this.dateValidator.bind(this));
      control.updateValueAndValidity({ onlySelf: true });
    }

    if (this.type === SmartFieldTypes.Lookup) {
      if (!this.subtype) {
        throw Error(
          `No 'subtype' specified for SmartFieldComponent with 'type' = 'lookup'!`
        );
      }
      if (this.excludedValues) {
        /*
          If the property excludedValues is set then we do client side filtering. Any item whose
          description is contained with 'excludedValues' is removed. This is currently being used
          in invoice-details-component.html to remove the status 'Paid' from the drop down of
          invoice statuses.
        */
        const myExcludedValues = this.excludedValues;
        const myFilter = function (item: LookupItem) {
          // create a closure to the outside variable
          return !myExcludedValues.includes(item.description);
        };
        this.entries$ = this.lookupService
          .getAllByType(<LookupTypes>this.subtype)
          .pipe(map((lookupItems) => lookupItems.filter(myFilter)));
      } else {
        // ? Gets ALL lookup types, and the HTML dynamically decides to make them disabled
        // ? if they are marked inactive in the DB.
        this.entries$ = this.lookupService.getAllByType(
          <LookupTypes>this.subtype
        );
      }
    }
  }

  autocompleteFilter(searchTerm: string): void {
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      this.filteredAutocompleteEntries = this.autocompleteEntries.filter(
        (entry) =>
          entry[this.autocompleteFilterTerm] &&
          entry[this.autocompleteFilterTerm]
            .toLowerCase()
            .includes(lowerCaseTerm)
      );
    } else {
      this.filteredAutocompleteEntries = [...this.autocompleteEntries];
    }
  }

  isDisplayMessageAvailable() {
    if (!this.displayMessage && !this.errorLogged) {
      this.errorLogged = true;
      console.error(
        `No 'displayMessage' set on ch-smart-field with propertyName '${this.propertyName}'`
      );
    }
    return !!this.displayMessage;
  }

  // The default property is 'id' unless overridden by
  // optionPropertyName
  getOptionValue(entry: any): any {
    let optionValuePropertyName = 'id';
    if (this.optionPropertyName) {
      // . returns the whole entry, rather than a property
      if (this.optionPropertyName === '.') {
        return entry;
      }
      optionValuePropertyName = this.optionPropertyName;
    }
    return entry[optionValuePropertyName];
  }

  getDropdownDisplay(entry: any): string {
    // If no 'dropdownDisplayFn' has been set, use a default property of 'description'.
    return this.dropdownDisplayFn
      ? this.dropdownDisplayFn(entry)
      : (<any>entry).description;
  }

  isAllowed(item: LookupItem): boolean {
    let isAllowed = true;
    if (this.disabledItems) {
      if (
        this.disabledItems.indexOf(item.id) > -1 ||
        this.disabledItems.indexOf(item.description) > -1 ||
        this.disabledItems.indexOf(item) > -1
      ) {
        isAllowed = false;
      }
    }
    return isAllowed;
  }

  onBlur(event: any) {
    this.blur.emit(event);
  }

  onChange(event: any) {
    this.change.emit(event);
  }

  // I think this exists solely to do additional transforms?
  // really not sure.
  getValue(propertyName: string): any {
    let value = null;

    if (this.formGroup) {
      const control = this.formGroup.get(propertyName);
      if (control) {
        value = control.value;
        if (value === undefined) {
          value = null;
        }
      } else {
        console.warn(`FormGroup Control not found for ${propertyName}`);
      }
    }

    return value;
  }

  dateValidator(control: FormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const parsedDate = new Date(control.value);
    const year = parsedDate.getFullYear();
    if (isNaN(parsedDate.getTime()) || year < 1900 || year > 9999) {
      return { invalidDate: true };
    }

    return null;
  }
}
